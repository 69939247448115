import { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      const response = await axiosPrivate.get("/client/dashboard", {
        signal: controller.signal,
      });
      setData(response.data.data.orders);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate(location, { state: { from: location }, replace: true });
      }
    }
  };
  useEffect( ()=>{
    getData();
    // eslint-disable-next-line
  },[])
  return (
    <>
      <section className="container mt-5">
        <div className="row">
          <div className="col-md-3">
            <div className="card bg-dark order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">Pending Orders</p>
                <h6 className="text-right">
                  <i className="fa fa-cart-plus f-left text-white"></i>
                  <span className="text-white px-3">{data?.pending}</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card bg-primary order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">On Delivery</p>
                <h6 className="text-right">
                  <i className="fa fa-rocket f-left text-white"></i>
                  <span className="text-white px-3">{data?.delivery}</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card bg-success order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">Completed Orders</p>
                <h6 className="text-right">
                  <i className="fa fa-credit-card f-left text-white"></i>
                  <span className="text-white px-3">{data?.completed}</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card bg-danger order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">Cancelled Orders</p>
                <h6 className="text-right">
                  <i className="fa fa-hourglass-end f-left text-white"></i>
                  <span className="text-white px-3">{data?.canceled}</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
