import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const Accounts = () => {
  useEffect(() => {
    document.title = "Profile - Chillies Restuarant";
  }, []);
  const progress = new ProgressBar({ color: "#9329", size: 4 });
  const [disabled, setDisabled] = useState(false);
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: profile?.fullname,
      contact: profile?.contact,
      email: profile?.email,
      address: profile?.address,
    },
  });
 const headers = {
   Authorization: localStorage.getItem("accessToken"),
   "Content-Type": "application/json",
 };
  const getProfile = async () => {
    // // Assuming verify is an object, not an array
    try {
      const response = await axiosPrivate.get("/accounts/profile", { headers });
      setProfile(response?.data?.data?.user);
      //  console.log(profile);
    } catch (error) {
      //  localStorage.clear();
      console.log(error);
    }
  };
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.patch("/accounts/profile", data, {
        headers
      });

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getProfile();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        // console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate(navigate, { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  useEffect(() => {
    reset({
      fullname: profile?.fullname,
      contact: profile?.contact,
      email: profile?.email,
      address: profile?.address,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  return (
    <>
      <section className="container mt-5">
        <div className="text-center mb-3">
          <h6>Profile Details</h6>
          <div className="row d-flex align-items-center justify-content-center mt-3">
            <form
              className="pq-booking-table-form col-md-6"
              autoComplete="off"
              onSubmit={handleSubmit(onUpdate)}
            >
              <div className="card card-body">
                <div className="row text-center ">
                  <div className="col-lg-6 col-md-6 mb-3">
                    <input
                      type="text"
                      autoFocus={true}
                      autoComplete="off"
                      className="form-control border-gray-400 mb-0"
                      id="fullname"
                      // disabled={profile?.fullname}
                      placeholder="Enter your fullname"
                      defaultValue={profile?.fullname}
                      {...register("fullname", {
                        required: true,
                      })}
                    />
                    {errors?.fullname?.type === "required" && (
                      <p className="text-small m-0 px-2 text-start text-danger help-block">
                        Please insert your fullname
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 mb-3">
                    <input
                      type="tel"
                      autoFocus={true}
                      autoComplete="off"
                      className="form-control border-gray-400 mb-0"
                      id="contact"
                      // disabled={profile?.contact}
                      defaultValue={profile?.contact}
                      placeholder="Enter your phone number"
                      {...register("contact", {
                        required: true,
                      })}
                    />

                    {errors?.contact?.type === "required" && (
                      <p className="text-small m-0 px-2 text-start text-danger help-block">
                        Please insert phone number
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 mb-3">
                    <input
                      type="email"
                      autoFocus={true}
                      autoComplete="off"
                      className="form-control border-gray-400 mb-0"
                      id="email"
                      // disabled={profile?.email}
                      defaultValue={profile?.email}
                      placeholder="Enter email (optional)"
                      {...register("email")}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <textarea
                      type="text"
                      autoFocus={true}
                      autoComplete="off"
                      className="form-control border-gray-400 mb-0"
                      id="address"
                      defaultValue={profile?.address}
                      placeholder="Enter prefered delivery address"
                      {...register("address", { required: true })}
                    />

                    {errors?.address?.type === "required" && (
                      <p className="text-small m-0 px-2 text-start text-danger help-block">
                        Please insert your address
                      </p>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <button
                      className="btn btn-success text-small pq-button"
                      disabled={disabled}
                    >
                      Update Profile Details
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accounts;
