import { useEffect, useState } from "react";
import MenuProductsComponent from "./menu-logic/menu-products-component";
const MenuProducts = ({ onAdd, cartItems, onRemove }) => {
  const [categoryTitle, setCategoryTitle] = useState([]);
  useEffect(() => {
    document.title = `Menu - ${categoryTitle} - Chillies Restuarant`;
  }, [categoryTitle]);

  return (
    <>
      <section className="container ">
        <div className="text-center mt-5">
          <h4>{categoryTitle}</h4>
          <p>
            Step into the Delectable World of Chillies! Explore our enticing
            array of breakfast delights, perfected beverages, and decadent
            treats, all the way to our generous and satisfying big meals. Your
            culinary journey begins right at your fingertips. Place your order
            now and savor the goodness!
          </p>
        </div>
        <MenuProductsComponent
          setCategoryTitle={setCategoryTitle}
          onAdd={onAdd}
          cartItems={cartItems}
          onRemove={onRemove}
        />
      </section>
    </>
  );
};

export default MenuProducts;
