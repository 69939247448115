import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "config/axios";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import SingleProductView from "pages/components/product-single-view";
const MenuProductsComponent = ({
  setCategoryTitle,
  onAdd,
  cartItems,
  onRemove,
}) => {
  const { id } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["menu-product"],
    queryFn: async () => {
      const fetchURL = `/menu/products/${id}`;
      try {
        const response = await axios.get(fetchURL);
        setCategoryTitle(response.data.data.categoryTitle);
        return response.data.data.products;
      } catch (error) {
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  const [product, setProduct] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setProduct([]);
    setShowModal(false);
  };
  const handleOpenModal = (data) => {
    setProduct(data);
    setShowModal(true);
  };
  return (
    <>
      {isLoading && (
        <div className="text-center">
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>{" "}
        </div>
      )}
      {!isLoading && (
        <div className="row">
          {data?.map((product) => (
            <div className="col-md-3 mb-4" key={product?.uid}>
              <div className="card bg-light shadow-lg border-0 rounded-3">
                <button
                  onClick={() => {
                    handleOpenModal(product);
                  }}
                  className="btn btn-link p-0"
                >
                  <img
                    src={product?.imageUrl}
                    loading="lazy"
                    className="card-img-top card-img-top-sm rounded-2"
                    alt="BreakfastImage"
                  />
                </button>
                <div className="card-body text-center bg-none">
                  <button
                    onClick={() => {
                      handleOpenModal(product);
                    }}
                    className="btn btn-link p-0 text-danger text-decoration-none w-100"
                  >
                    {product?.title} <br />
                    <span className="text-dark text-sm">
                      UGX {product?.price?.toLocaleString()}{" "}
                    </span>
                  </button>
                  {!cartItems?.some((item) => item?.id === product?.id) && (
                    <button
                      onClick={() => onAdd(product)}
                      className="btn btn-danger text-small py-2 w-100 d-block btn-sm"
                    >
                      Order
                    </button>
                  )}
                  {cartItems.some((item) => item.id === product.id) && (
                    <div className="input-group container">
                      <span className="input-group-btn">
                        <button
                          onClick={() => onRemove(product)}
                          className="btn btn-danger text-small"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </span>
                      {cartItems.map(
                        (item) =>
                          item.id === product.id && (
                            <p
                              key={item.id}
                              className="border-0 text-center text-small form-control"
                            >
                              {item.qty}
                            </p>
                          )
                      )}
                      <span className="input-group-btn">
                        <button
                          type="button"
                          onClick={() => onAdd(product)}
                          className="btn-block btn btn-danger text-small"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <SingleProductView
        data={product}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default MenuProductsComponent;
