import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Missing from "layouts/404";
import Home from "./pages/home";
import AboutUs from "pages/about";
import Carrers from "pages/carrers";
import Location from "pages/location";
import ContactUs from "pages/contactus";
import MenuCategories from "pages/menu";
import MenuSubCategories from "pages/menu-sub-caategories";
import MenuProducts from "pages/menu-products";
import toast, { Toaster } from "react-hot-toast";
import ScrollToTop from "layouts/scroll.Top";
import { useState, useEffect } from "react";
import Dashboard from "pages/dashboard";
import OrderHistory from "pages/order-history";
import OrderTrack from "pages/order-track";
import Accounts from "pages/accounts";
// import RequiredAuth from "middlewares/hooks/requiredLogin";
import Login from "pages/login";
import Cart from "pages/cart";
import PrivateRoute from "middlewares/hooks/private";
import CheckOut from "pages/checkout";
function App() {
  const notifyNewProduct = () => toast("New Product Added to Cart.");
  const notifyRemoveProduct = () => toast("Product Deleted.");
  const notifyUpdateProduct = () => toast("Your Cart Has Been Updated");
  const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const [cartItems, setCartItems] = useState(storedCartItems);
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);
  const onAdd = (product) => {
    const exist = cartItems?.find((x) => x?.id === product?.id);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x?.id === product?.id ? { ...exist, qty: exist.qty + 1 } : x
        )
      );
      notifyUpdateProduct();
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }]);
      notifyNewProduct();
    }
  };

  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
      notifyRemoveProduct();
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
      notifyUpdateProduct();
    }
  };
  const onDelete = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
      notifyRemoveProduct();
    }
  };
  const [isAuth, setIsAuth] = useState(false);
// console.log(cartItems)
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <ScrollToTop />
      <Routes>
        <Route
          path=""
          element={
            <Layout
              setCartItems={setCartItems}
              onDelete={onDelete}
              onRemove={onRemove}
              onAdd={onAdd}
              cartItems={cartItems}
              isAuth={isAuth}
            />
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
          <Route path="/carrers" element={<Carrers />} />
          <Route path="/location" element={<Location />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/menu" element={<MenuCategories />} />
          <Route path="/menu/:id" element={<MenuSubCategories />} />
          <Route
            path="/cart"
            element={
              <Cart
                onDelete={onDelete}
                cartItems={cartItems}
                onRemove={onRemove}
                onAdd={onAdd}
              />
            }
          />
          <Route
            path="/menu/products/:id"
            element={
              <MenuProducts
                setCartItems={setCartItems}
                onDelete={onDelete}
                onRemove={onRemove}
                onAdd={onAdd}
                cartItems={cartItems}
              />
            }
          />
          <Route element={<PrivateRoute />}>
            <Route
              path="check-out"
              element={
                <CheckOut
                  onRemove={onRemove}
                  onAdd={onAdd}
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                />
              }
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/orders" element={<OrderHistory />} />
            <Route path="/track-order/:id" element={<OrderTrack />} />
            <Route path="/profile" element={<Accounts />} />
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
