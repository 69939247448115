import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosPrivate } from "config/axios";
import OrderHistorySummary from "./components/order-summary";
import { keepPreviousData, useQuery } from "@tanstack/react-query";


const OrderHistory = () => {
  useEffect(() => {
    document.title = "Order History - Chillies Restuarant";
  }, []);
  // const data = {};
  const headers = {
    Authorization: localStorage.getItem("accessToken"),
    "Content-Type": "application/json",
  };

    const { data } = useQuery({
      queryKey: ["order-history"],
      queryFn: async () => {
        try {
             const response = await axiosPrivate.get("/client/orders", {
               headers,
             });
          return response.data.data.order;
        } catch (error) {
          console.error("Axios request failed", error);
          throw error;
        }
      },
      placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
    });
  // console.log(orders )
  const [orderItems, setOrderItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (data) => {
    // console.log(data);
    setOpenModal(true);
    setOrderItems(data);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOrderItems([]);
  };
  return (
    <>
      <section className="container mt-5">
        <div className="text-center mb-3">
          <h6>Order history</h6>
          <form className="form-inline">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="card bg-dark order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">Pending Orders</p>
                <h6 className="text-right">
                  <i className="fa fa-cart-plus f-left text-white"></i>
                  <span className="text-white px-3">
                    {
                      data?.filter((order) => order.status === "pending")
                        .length
                    }
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card bg-primary order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">On Delivery</p>
                <h6 className="text-right">
                  <i className="fa fa-rocket f-left text-white"></i>
                  <span className="text-white px-3">
                    {" "}
                    {
                      data?.filter((order) => order.status === "delivery")
                        .length
                    }
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card bg-success order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">Completed Orders</p>
                <h6 className="text-right">
                  <i className="fa fa-credit-card f-left text-white"></i>
                  <span className="text-white px-3">
                    {" "}
                    {
                      data?.filter((order) => order.status === "completed")
                        .length
                    }
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card bg-danger order-card text-white">
              <div className="card-block">
                <p className="mb-2 text-white">Cancelled Orders</p>
                <h6 className="text-right">
                  <i className="fa fa-hourglass-end f-left text-white"></i>
                  <span className="text-white px-3">
                    {" "}
                    {
                      data?.filter((order) => order.status === "cancelled")
                        .length
                    }
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((order) => (
            <div className="col-md-3" key={order?.id}>
              <div className="card card-body shadow-lg">
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-end text-uppercase">
                      {order.status}
                    </div>
                    <span>
                      <strong>Order Number: </strong>
                    </span>{" "}
                    <span className="label label-info">
                      {order?.order_number}
                    </span>
                    <br />
                    No. Items : {order?.orderItems?.length} <br /> Total: UGX{" "}
                    {order?.totalPrice?.toLocaleString()}
                    <br />
                    <button
                      onClick={() => {
                        handleOpenModal(order?.orderItems);
                      }}
                      className="btn btn-success btn-sm text-small"
                    >
                      {" "}
                      View Items
                    </button>
                    <OrderHistorySummary
                      handleCloseModal={handleCloseModal}
                      showModal={openModal}
                      orderItems={orderItems}
                    />
                    <Link
                      to={`/track-order/${order?.order_number}`}
                      className="btn btn-dark btn-sm text-small mx-1"
                    >
                      {" "}
                      Track Order
                    </Link>
                  </div>
                  <div className="col-md-12">
                    Order Date: {new Date(order?.timestamp).toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-2">
          <div className="col-md-12 ">
            <nav aria-label="Page navigation float-end ">
              <ul className="pagination">
                <li className="page-item">
                  <Link
                    to=""
                    className="page-link btn btn-sm btn-link"
                    aria-label="Previous"
                  >
                    <span
                      aria-hidden="true"
                      className="text-dark fa fa-arrow-left"
                    ></span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    className="page-link btn btn-sm btn-link"
                    to=""
                    aria-label="Next"
                  >
                    <span
                      aria-hidden="true"
                      className="text-dark fa fa-arrow-right"
                    ></span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderHistory;
