import { useEffect } from "react";
import MenuCategoriesComponent from "./menu-logic/menu-categories";
const MenuCategories = () => {
  useEffect(() => {
    document.title = "Menu - Chillies Restuarant";
  }, []);
  return (
    <>
      <section className="container">
        <div className="text-center mt-5">
          <h4>Welcome To Chillies</h4>
          <div className="jumbotron">
            <p className="display-7">
              Step into the Delectable World of Chillies! Explore our enticing
              array of breakfast delights, perfected beverages, and decadent
              treats, all the way to our generous and satisfying big meals. Your
              culinary journey begins right at your fingertips. Place your order
              now and savor the goodness!
            </p>
          </div>
        </div>
        <MenuCategoriesComponent />
      </section>
    </>
  );
};

export default MenuCategories;
