// import Image from "assets/imgs/other/body/NNP_0269.jpg";
import { Link } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "config/axios";
const MenuCategoriesComponent = () => {

  const { data, isLoading } = useQuery({
    queryKey: ["menu-data"],
    queryFn: async () => {
      const fetchURL = `/menu/categories`;

      try {
        const response = await axios.get(fetchURL);
        return response.data.data.categories;
      } catch (error) {
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  return (
    <>
      {isLoading && (
        <div className="text-center">
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>{" "}
        </div>
      )}
      {!isLoading && (
        <div className="row">
          {data?.map((category) => (
            <Link
              to={`/menu/${category?.uid}`}
              className="col-md-4 mb-4"
              key={category?.id}
            >
              <div className="card bg-light shadow-lg border-0 rounded-3">
                <img
                  src={category?.imageUrl}
                  loading="lazy"
                  className="card--top card-img-top-lg rounded-2"
                  alt="BreakfastImage"
                />
                <div className="card-body text-center bg-transparent">
                  <h6 className="display-10">{category?.title}</h6>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

export default MenuCategoriesComponent;
