import { useEffect } from "react";
const Location = () => {
    useEffect(() => {
      document.title = "Location - Chillies Restuarant";
    }, []);
    return (
      <>
        <section className="services pq-bg-img-1 pq-bg-img-2">
          <div className="pq-bg-grey mt-5">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 ps-xl-5 mt-5">
                  <div className="pq-sticky-top">
                    <div className="pq-section pq-style-1">
                      <span className="pq-section-sub-title">Our Locations</span>
                    </div>
                    <p className="pq-sticky-description">
                      We are very proud to say that after 14 years of serving
                      this great city, our Kampala based company is still owned
                      and operated by the same founding Ugandans. We are greatly
                      generous for your support over the year and look forward
                      to serving you better. If you have experience in the
                      hospitality industry and are looking for a business
                      opportunity we selectively offer franchise opportunities
                      outside Kampala. As always we are interested in your
                      comments and suggestion so please feel free to email us
                      directly with your comments at admin@chillies.co.ug
                    </p>
                  </div>
                </div>
                <div className="col-xl-5 col-sm-12 mt-xl-0">
                  <div className="card shadow-lg border-0 rounded-1 pq-icon pq-style-1 mt-5">
                    <div className="card-header bg-white">
                      <h6 className=" pq-icon-box-title">
                        Bukoto Ntinda Rd, Kampala
                      </h6>
                    </div>
                    <div className="card-body">
                      Hours: Open 24 hours <br />
                      Phone: 0752 797009
                    </div>
                  </div>
                  <div className="card shadow-lg border-0 rounded-1 pq-icon pq-style-1 mt-5">
                    <div className="card-header bg-white">
                      <h6 className=" pq-icon-box-title">
                        Acacia Mall, Sturrock Rd, Kampala
                      </h6>
                    </div>
                    <div className="card-body">
                      Hours: Open 24 hours <br /> Phone: 0752 797013 <br />{" "}
                      Reservations: chillies.co.ug <br /> Order: chillies.co.ug{" "}
                      <br />
                    </div>
                  </div>
                  <div className="card shadow-lg border-0 rounded-1 pq-icon pq-style-1 mt-5">
                    <div className="card-header bg-white">
                      <h6 className=" pq-icon-box-title">Luthuli Rise, Kampala</h6>
                    </div>
                    <div className="card-body">
                      Hours: Open 24 hours <br /> Reservations: chillies.co.ug{" "}
                      <br /> Order: chillies.co.ug <br /> Phone: 0752 797011
                    </div>
                  </div>
                  <div className="card shadow-lg border-0 rounded-1 pq-icon pq-style-1 mt-5">
                    <div className="card-header bg-white">
                      <h6 className=" pq-icon-box-title">
                        Kampala Jinja Road, Kampala
                      </h6>
                    </div>
                    <div className="card-body">
                      Hours: Open 08:00 am – 10:00 pm <br /> Reservations:
                      chillies.co.ug <br /> Order: chillies.co.ug <br /> Phone:
                      0752 797005
                    </div>
                  </div>
                  <div className="card shadow-lg border-0 rounded-1 pq-icon pq-style-1 mt-5">
                    <div className="card-header bg-white">
                      <h6 className=" pq-icon-box-title">
                        Kampala Jinja Road, Kampala
                      </h6>
                    </div>
                    <div className="card-body">
                      Hours: Open 08:00 am – 10:00 pm <br /> Reservations:
                      chillies.co.ug <br /> Order: chillies.co.ug <br /> Phone:
                      0752 797010
                    </div>
                  </div>
                  <div className="card shadow-lg border-0 rounded-1 pq-icon pq-style-1 mt-5">
                    <div className="card-header bg-white">
                      <h6 className=" pq-icon-box-title">
                        1st Street Industrial Area, Kampala
                      </h6>
                    </div>
                    <div className="card-body">
                      Hours: Open 08:00 am – 10:00 pm <br /> Reservations:
                      chillies.co.ug <br /> Order: chillies.co.ug <br /> Phone:
                      0752 797008
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}
 
export default Location;