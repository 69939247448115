import { Link } from "react-router-dom";
import {useEffect} from "react";	
const Cart = ({ cartItems, onAdd, onRemove, onDelete }) => {
  useEffect(() => {
    document.title = "Cart - Chillies Restuarant";
  }, []);
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);

  return (
    <>
      <section className="mb-lg-14 mb-8 mt-8">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card py-3 border-0 mb-8">
                <div>
                  <h6 className="fw-bold text-center">Food Cart</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-7">
              {cartItems.length !== 0 && (
              <div className="py-3">
                <ul className="list-group list-group-flush">
                  {cartItems.map((item) => (
                    <li
                      className="list-group-item py-3 ps-0 border-bottom"
                      key={item.id}
                    >
                      <div className="row align-items-center">
                        <div className="col-6 col-md-6 col-lg-7">
                          <div className="d-flex">
                            <img
                              src={item.imageUrl}
                              alt="NoImage"
                              width={"100px"}
                            />
                            <div className="ms-3">
                              <Link
                                to={`/product/${item.uid}`}
                                className="text-inherit"
                              >
                                <h6 className="mb-0">{item.title}</h6>
                              </Link>
                              <span>
                                <small className="text-muted">
                                  {item.description}
                                </small>
                              </span>
                              <div className="mt-2 small lh-1">
                                <button
                                  onClick={() => onDelete(item)}
                                  className="btn text-small btn-danger btn-sm"
                                >
                                  <i className="fa fa-trash"></i> Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-md-2 col-lg-2">
                          <div className="action-btn">
                            <div>
                              <div>
                                <div className="input-group">
                                  <span className="input-group-btn">
                                    <button
                                      onClick={() => onRemove(item)}
                                      className="btn-block text-small btn-sm quantity-left-minus btn btn-success btn-number"
                                    >
                                      <i className="fa fa-minus"></i>
                                    </button>
                                  </span>

                                  <p className="text-center text-small form-control form-control-sm">
                                    {item.qty}
                                  </p>

                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => onAdd(item)}
                                      className="btn-block text-small btn-sm quantity-right-plus btn btn-success btn-number"
                                    >
                                      <i className="fa fa-plus"></i>
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 text-lg-end text-start text-md-end col-md-2">
                          <span className="fw-bold">
                            UGX{" "}
                            {(item.price * item.qty).toLocaleString("en-US")}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="d-flex justify-content-between mt-4">
                  <Link to="/menu" className="btn  text-small btn-sm btn-dark">
                    Continue Shopping
                  </Link>
                  <Link to="/check-out" className="btn text-small  btn-sm btn-danger">
                    Check Out
                  </Link>
                </div>
              </div>
              )}
               {cartItems.length === 0 && (
                <div>
                  <div className="card py-3 border-0 mb-8">
                    <div className="v-10 text-center p-3">
                      <img src="/assets/images/no-cart.svg" alt="noImage" width={"150px"}/>
                    </div>
                    <div className="text-center">
                      <Link to="/menu" className="btn btn-dark fw-bold text-center text-small">Start Order</Link>
                    </div>
                  </div>
                </div>
               )}
            </div>

            <div className="col-12 col-lg-4 col-md-5">
              <div className="mb-5 card mt-6">
                <div className="card-body p-6">
                  <h6 className="h6 mb-4">Food Cart Total </h6>
                  <div className="card mb-2">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div>Total Number Of Items</div>
                        </div>
                        <span>{cartItems?.length}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div>Sub Total </div>
                        </div>
                        <span>{itemsPrice.toLocaleString("en-US")}</span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div className="fw-bold">Total</div>
                        </div>
                        <span className="fw-bold">
                          UGX {itemsPrice.toLocaleString("en-US")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {cartItems.length !== 0 && (
                  <div className="d-grid mb-1 mt-4">
                    <Link
                      className="btn btn-danger text-small d-flex justify-content-between align-items-center"
                      to="/check-out"
                    >
                      Continue To Check Out
                      <span className="fw-bold">
                        UGX {itemsPrice.toLocaleString("en-US")}
                      </span>
                    </Link>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
