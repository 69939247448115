import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "config/axios";

const CheckOut = ({ cartItems, setCartItems }) => {
  useEffect(() => {
    document.title = "Checkout - Chillies Restuarant";
  }, []);
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);
  const headers = {
    Authorization: localStorage.getItem("accessToken"),
    "Content-Type": "application/json",
  };
  const [profile, setProfile] = useState([]);

  const getProfile = async () => {
    const response = await axiosPrivate.get("/accounts/profile", { headers });
    setProfile(response.data.data.user);
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: profile?.fullname,
      contact: profile?.phoneNo,
      email: profile?.emailAddress,
      address: profile?.Address,
    },
  });
  useEffect(() => {
    reset({
      fullname: profile?.fullname,
      contact: profile?.phoneNo,
      email: profile?.emailAddress,
      address: profile?.Address,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const onUpdate = async (data) => {
    try {
      setDisabled(true);
      await axiosPrivate.patch("/accounts/profile", data, {
        headers,
      });
      reset();
      getProfile();
      onSubmit({
        itemsPrice: itemsPrice,
      });
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await axiosPrivate.post("client/orders", data, {
        headers,
      });
      // console.log(response.data.data.order.id);
      await submitOrderItems(response.data.data.order.id);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const submitOrderItems = async (orderId) => {
    try {
      const response = await axiosPrivate.post(
        "/client/orders-items",
        {
          orderId: orderId,
          items: cartItems,
        },
        {
          headers,
        }
      );
      toast.success(response.data.messages);
      setDisabled(false);
      setCartItems([]);
      navigate("/orders");
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <>
      {" "}
      <section className="mb-lg-50 mt-5">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="card text-center mb-3">
                  <h6 className="card-header">Delivery Details</h6>
                  <form
                    className="pq-booking-table-form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onUpdate)}
                  >
                    <div className="">
                      <div className="row card-body text-center ">
                        <div className="col-lg-6 col-md-6 mb-3">
                          <input
                            type="text"
                            autoFocus={true}
                            autoComplete="off"
                            className="form-control border-gray-400 mb-0"
                            id="fullname"
                            // disabled={profile?.fullname}
                            placeholder="Enter your fullname"
                            defaultValue={profile?.fullname}
                            {...register("fullname", {
                              required: true,
                            })}
                          />
                          {errors?.fullname?.type === "required" && (
                            <p className="text-small m-0 px-2 text-start text-danger help-block">
                              Please insert your fullname
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                          <input
                            type="tel"
                            autoFocus={true}
                            autoComplete="off"
                            className="form-control border-gray-400 mb-0"
                            id="contact"
                            // disabled={profile?.contact}
                            defaultValue={profile?.contact}
                            placeholder="Enter your phone number"
                            {...register("contact", {
                              required: true,
                            })}
                          />

                          {errors?.contact?.type === "required" && (
                            <p className="text-small m-0 px-2 text-start text-danger help-block">
                              Please insert phone number
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                          <input
                            type="email"
                            autoFocus={true}
                            autoComplete="off"
                            className="form-control border-gray-400 mb-0"
                            id="email"
                            // disabled={profile?.email}
                            defaultValue={profile?.email}
                            placeholder="Enter email (optional)"
                            {...register("email")}
                          />
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <textarea
                            type="text"
                            autoFocus={true}
                            autoComplete="off"
                            className="form-control border-gray-400 mb-0"
                            id="address"
                            defaultValue={profile?.address}
                            placeholder="Enter prefered delivery address"
                            {...register("address", { required: true })}
                          />

                          {errors?.address?.type === "required" && (
                            <p className="text-small m-0 px-2 text-start text-danger help-block">
                              Please insert your address
                            </p>
                          )}
                        </div>

                        <div className="col-lg-12 mt-4">
                          <button
                            className="btn btn-success text-small pq-button"
                            disabled={disabled}
                          >
                            {!disabled && "Continue To Place Order"}{" "}
                            {disabled && (
                              <>
                                {" "}
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                Please Wait Placing Order ...
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-12 col-md-12 offset-lg-1 col-lg-4">
                <div className="mb-5 card mt-6">
                  <div className="card-body p-6">
                    <h2 className="h5 mb-4">Order Summary</h2>
                    <div className="p-0 mb-2 ">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                          <div className="me-auto">
                            <div>Total Number Of Items</div>
                          </div>
                          <span>{cartItems?.length}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                          <div className="me-auto">
                            <div>Sub Total </div>
                          </div>
                          <span>{itemsPrice.toLocaleString("en-US")}</span>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                          <div className="me-auto">
                            <div className="fw-bold">Total</div>
                          </div>
                          <span className="fw-bold">
                            UGX {itemsPrice.toLocaleString("en-US")}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckOut;
