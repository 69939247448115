import LogoDark from "assets/imgs/logos/logo.png";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const Footer = () => {

    return (
      <>
        <FloatingWhatsApp
          phoneNumber={"+256752797000"}
          accountName={"Chillies Restuarant"}
          chatMessage={"Make your order or leave us a message"}
          allowClickAway={true}
          className=""
          avatar={LogoDark}
        />
        <footer id="pq-" className="bg-dark fixed-bottom text-white">
          <div className="pq-footer-style-1 p-2">
            <div className="pq-copyright-footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <span className="pq-copyright">
                      {" "}
                      &copy; Chillies Uganda 2024. All Rights Reserved.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
}
 
export default Footer;