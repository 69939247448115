import { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "config/axios";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import AuthContext from "context/AuthProvider";

const Login = ({ setIsAuth }) => {
  useEffect(() => {
    document.title = "Login - Chillies Restuarant";
  }, []);
   const { setAuth, login } = useContext(AuthContext);
   const [isPending, setIsPending] = useState(false);
   const [isLogin, setIsLogin] = useState(false);
   const [pincode, setPincode] = useState("");
   const [username, setUsername] = useState("");
   const navigate = useNavigate();
   // const location = useLocation();
  //  const from = localStorage.getItem("redirectTo") || "/orders";

   const {
     register,
     handleSubmit,
     formState: { errors },
   } = useForm();
   const onSubmit = (postdata) => {
     setIsPending(true);
     axios({
       method: "post",
       url:  "/auth/token",
       headers: {
         "Content-Type": "application/json",
       },
       data: JSON.stringify(postdata),
     })
       .then((response) => {
         // console.log(response.data);
         localStorage.setItem("token", response.data.data.token);
         setIsPending(false);
         setIsLogin(true);
        //  navigate(from, { replace: true });
        //  localStorage.removeItem("redirectTo");
       })
       .catch((error) => {
         // console.log(error.response.data.messages);
         setIsPending(false);
         console.error(error);
         toast.error(error.response.data.messages[0]);
       });
   };

   const onSubmitTwo = (otpdata) => {
     // console.log(otpdata)
     setIsPending(true);
     axios({
       method: "post",
       url: "/auth/sessions",
       headers: {
         "Content-Type": "application/json",
         Authorization: localStorage.getItem("token"),
       },
       data: JSON.stringify(otpdata),
     })
       .then((response) => {
         // console.log(response.data);
         const accessToken = response.data.data.access_token;
         const refreshToken = response.data.data.refresh_token;
         setAuth({ accessToken, refreshToken });
         // console.log(setAuth);
         localStorage.setItem("sessionID", response.data.data.session_id);
         localStorage.setItem("accessToken", response.data.data.access_token);
         localStorage.setItem("refreshToken", response.data.data.refresh_token);
         setIsPending(false);
        //  navigate(from, { replace: true });
        navigate(-1)
         login();
         setIsAuth(true);
         localStorage.removeItem("redirectTo");
       })
       .catch((error) => {
         // console.log(error.response.data.messages);
         setIsPending(false);
         for (var i = 0; i < error.response.data.messages.length; i++) {
           toast.error(error.response.data.messages[i]);
         }
       });
   };
   const handlegoogleLogin = async (response) => {
     const userData = jwtDecode(response.credential);
    //  console.log(userData);
     const data = JSON.stringify({
       names: userData.name,
       email: userData.email,
     });
    //  console.log(data);
     await axios({
       method: "post",
       url:  "/auth/google",
       headers: {
         "Content-Type": "application/json",
       },
       data: data,
     })
       .then((response) => {
         // console.log(response)
         localStorage.setItem("sessionID", response.data.data.session_id);
         localStorage.setItem("accessToken", response.data.data.access_token);
         localStorage.setItem("refreshToken", response.data.data.refresh_token);
         setIsPending(false);
         navigate(-1);
         login();
         setIsAuth(true);
         localStorage.removeItem("redirectTo");
       })
       .catch((error) => {
        // console.log(error)
         for (var i = 0; i < error.response.data.messages.length; i++) {
           toast.error(error.response.data.messages[i]);
         }
       });
   };

  return (
    <section className="my-lg-5 my-8" style={{marginTop: "90px"}}>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 card shadow col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1 p-4">
            {!isLogin && (
              <div className="card-header bg-white">
                <h5 className="text-center mb-1">
                    Login
                </h5>
              </div>
            )}
            {isLogin && (
              <div className="card-header">
                <h5 className="mb-1 fw-bold text-center">Verify</h5>
              </div>
            )}
            {!isLogin && (
              <form
                className="needs-validation card-body"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row g-3">
                  <div className="col-12">
                    <label
                      htmlFor="phonenumber"
                      className="form-label visually-hidden"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phonenumber"
                      placeholder="Phone Number : eg. 0755 **** or 256755"
                      autoComplete="off"
                      {...register("username", {
                        required: true,
                        maxLength: 10,
                        minLength: 10,
                      })}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    {errors.username && (
                      <p className="text-center text-small text-danger help-block">
                        Valid phone is required e.g. 0759060010.
                      </p>
                    )}
                  </div>

                  <div className="col-12 d-grid">
                    {!isPending && (
                      <button
                        type="submit"
                        className="float-end w-100 btn btn-success text-white mt-1"
                      >
                        Continue
                      </button>
                    )}
                    {isPending && (
                      <button
                        type="submit"
                        disabled
                        className="float-end w-100 btn btn-success text-white"
                      >
                        Loading
                      </button>
                    )}
                  </div>
                 
                  <div className="col-12 d-grid">
                    <GoogleLogin
                      onSuccess={handlegoogleLogin}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                      width={"352"}
                    />
                  </div>
                </div>
              </form>
            )}
            {isLogin && (
              <form
                className="needs-validation card-body"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitTwo)}
              >
                <div className="row g-3">
                  <div className="col-12">
                    <label
                      htmlFor="phonenumber"
                      className="form-label visually-hidden"
                    >
                      OTP Code{" "}
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phonenumber"
                      placeholder="Enter OTP Code"
                      autoComplete="off"
                      {...register("pincode", {
                        required: true,
                        maxLength: 5,
                        minLength: 5,
                      })}
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />

                    {errors.pincode && (
                      <p className="text-center text-danger text-small help-block">
                        please enter a valid otpCode.
                      </p>
                    )}
                  </div>

                  <div className="col-12 d-grid">
                    <div className="mb-5">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm float-end"
                      >
                        Resend OTP
                      </button>
                    </div>

                    {!isPending && (
                      <button
                        type="submit"
                        className="float-end w-100 btn btn-success text-white"
                      >
                        Submit
                      </button>
                    )}
                    {isPending && (
                      <button
                        type="submit"
                        disabled
                        className="float-end w-100 btn btn-success text-white"
                      >
                        Loading
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
