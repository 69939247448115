import { useEffect } from "react";
const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Chillies Restuarant";
  }, []);
    return (
      <>
        <section className="contact">
          <div className="container mt-5">
            <div className="row text-center">
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="pq-contact-box">
                  <div className="pq-contact-box-icon">
                    <i className="ion ion-ios-email-outline "></i>
                  </div>
                  <div className="pq-contact-box-content">
                    <h6 className="pq-contact-box-title">Email Address</h6>
                    <p className="pq-contact-box-description">
                      info@children.co.ug
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 mt-4 mt-md-0">
                <div className="pq-contact-box">
                  <div className="pq-contact-box-icon">
                    <i className="ion ion-ios-telephone-outline "></i>
                  </div>
                  <div className="pq-contact-box-content">
                    <h6 className="pq-contact-box-title">Business Phone</h6>
                    <p className="pq-contact-box-description">
                      +256-772-849-936 / +256-752-797-007
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 mt-4 mt-xl-0">
                <div className="pq-contact-box">
                  <div className="pq-contact-box-icon">
                    <i className="ti-location-pin "></i>
                  </div>
                  <div className="pq-contact-box-content">
                    <h6 className="pq-contact-box-title">Our Address</h6>
                    <p className="pq-contact-box-description">
                      Acacia mall Sturrock Road, Kisementi
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="map">
          <div className="pq-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.748818925433!2d32.584381074562536!3d0.33818826399305074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbba665f467bf%3A0xdb272dcf8ec999a2!2sChillies!5e0!3m2!1sen!2sug!4v1701792668779!5m2!1sen!2sug"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title={"books"}
            ></iframe>
          </div>
        </div>
        <section className="contact-form pt-xl-0 pq-mt-500">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-xl-6 p-xl-0">
                <div className="pq-contact-form-div pq-bg-grey">
                  <div className="pq-section pq-style-1 pq-mb-40">
                    <span className="pq-section-sub-title">contact us</span>
                    <h5 className="pq-section-title">Get In Touch </h5>
                  </div>
                  <form className="pq-contact-us-form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input type="text" placeholder="Enter Your Name" />
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <input type="text" placeholder="Enter Your Email" />
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <input type="text" placeholder="Phone Number" />
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <input type="text" placeholder="Subject" />
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          cols="40"
                          rows="5"
                          placeholder="Write Your Message"
                        ></textarea>
                      </div>
                      <div className="col-lg-12">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            console.log("clicked");
                          }}
                          className="pq-button btn btn-outline-danger"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}
 
export default ContactUs;