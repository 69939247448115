import { Modal } from "react-bootstrap";
const SingleProductView = ({ handleCloseModal, showModal, data }) => {
  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>{data?.title}</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-0">
            <div className="col-md-5">
              <img src={data?.imageUrl} className="img-fluid" alt="noImage" />
            </div>
            <div className="col-md-7">
              <table className="p-0 border-none">
                <tr>
                  <td className="text-dark"> Price </td>
                  <td className="text-dark">
                    {" "}
                    UGX {data?.price?.toLocaleString()}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="text-dark"> Description </td>
                  <td className="text-dark"> {data?.description} </td>
                </tr>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleCloseModal}
            className="btn btn-dark"
          >
            {" "}
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleProductView;
