import { Link } from "react-router-dom";
import Image1 from "assets/imgs/other/body/1.png";
import Image2 from "assets/imgs/other/body/2.png";
import FoodImage from "assets/imgs/other/body/NNP_0269.jpg";
import CarryFoodImage from "assets/imgs/other/body/NNP_8872.jpg";
import {useEffect} from "react";
const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Chillies Restuarant";
  }, []);
    return (
      <>
        <section className="about-us pq-pb-0 pb-0">
          <img
            src={Image1}
            className="pq-floating-image-left wow animated fadeInLeft"
            alt=""
          />
          <img
            src={Image2}
            className="pq-floating-image-right wow animated fadeInRight"
            alt=""
          />
          <div className="container pt-5">
            <div className="row align-items-center">
              <div className="col-xl-6">
                <div className="pq-about-us-img">
                  <img src={FoodImage} className="pq-about-us-img-1" alt="" />
                  <img src={CarryFoodImage} className="pq-about-us-img-2" alt="" />
                </div>
              </div>
              <div className="col-xl-6 mt-4 mt-xl-0 ps-xl-5">
                <div className="pq-section pq-style-1">
                  <span className="pq-section-sub-title">About us</span>
                  <h5 className="pq-section-title">
                    Welcome to Chillies Restuarant
                  </h5>
                </div>
                <p>
                  Chillies was founded in 2002 by a Uganda based partnership.
                  Situated in most suburbs of Kampala, the concept was simply to
                  create great selection of simple but affordable menu that was
                  well suited for local and working people, served freshly
                  cooked, eat in or take away. After 14 years of fine-tuning,
                  product development, and loads of hard work, we have what you
                  see today. As of 2016, we have eight locations.
                </p>
                <p>
                  Today, we still offer a cozy atmosphere with great value and
                  serve excellent quality, fresh cooked meals. We are defined as
                  a main stream fast-food restaurant with a menu that caters for
                  children, the community and local businesses. We provide
                  friendly, attentive service in a relaxed environment by staff
                  and owners that truly enjoy their job.
                </p>
                <Link className="pq-button pq-button-flat" to="/contactus">
                  <div className="pq-button-block">
                    <span className="pq-button-text">Contact Us</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="services pq-bg-img-1 pq-bg-img-2">
          <div className="pq-bg-grey">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="pq-section pq-style-1 text-center">
                    <span className="pq-section-sub-title">
                      Why we are the best
                    </span>
                    {/* <h5 className="pq-section-title">Why we are the best</h5> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="pq-fancy-box pq-style-1">
                    <div className="pq-fancy-media">
                      <div className="pq-fancy-box-icon">
                        <i className="flaticon-tray" aria-hidden="true"></i>
                      </div>
                      <div className="pq-fancy-box-info">
                        <h5 className="pq-fancy-box-title">
                          Culinary Excellence and Quality
                        </h5>
                      </div>
                    </div>
                    {/* <p className="pq-fancybox-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum dummy text
                    </p> */}
                    {/* <div className="pq-btn-container">
                      <a
                        className="pq-button pq-button-link"
                        href="our-services.html"
                      >
                        <div className="pq-button-block">
                          <span className="pq-button-text">read more </span>
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mt-4 mt-md-0">
                  <div className="pq-fancy-box pq-style-1">
                    <div className="pq-fancy-media">
                      <div className="pq-fancy-box-icon">
                        <i className="flaticon-coffee-cup" aria-hidden="true"></i>
                      </div>
                      <div className="pq-fancy-box-info">
                        <h5 className="pq-fancy-box-title">
                          Exceptional Service and Atmosphere
                        </h5>
                      </div>
                    </div>
                    {/* <p className="pq-fancybox-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum dummy text
                    </p> */}
                    {/* <div className="pq-btn-container">
                      <a
                        className="pq-button pq-button-link"
                        href="our-services.html"
                      >
                        <div className="pq-button-block">
                          <span className="pq-button-text">read more </span>
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mt-4 mt-xl-0">
                  <div className="pq-fancy-box pq-style-1">
                    <div className="pq-fancy-media">
                      <div className="pq-fancy-box-icon">
                        <i className="flaticon-fast-food-1" aria-hidden="true"></i>
                      </div>
                      <div className="pq-fancy-box-info">
                        <h5 className="pq-fancy-box-title">
                          Consistency and Reputation
                        </h5>
                      </div>
                    </div>
                    {/* <p className="pq-fancybox-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum dummy text
                    </p> */}
                    {/* <div className="pq-btn-container">
                      <a
                        className="pq-button pq-button-link"
                        href="our-services.html"
                      >
                        <div className="pq-button-block">
                          <span className="pq-button-text">read more </span>
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
   
      </>
    );
}
 
export default AboutUs;