import { Link, useNavigate } from "react-router-dom";
import Logo from "assets/imgs/logos/logo.png";
import { useState } from "react";
import ConfirmModal from "./confirm-modal";

import AuthContext from "context/AuthProvider";
import { useContext } from "react";

const Header = ({ cartItems, onAdd, onRemove, onDelete }) => {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useContext(AuthContext);

  const [logoutModal, setLogoutModal] = useState(false);

  const handleLogoutModalOpen = () => setLogoutModal(true);
  const handleLogoutModalClose = () => setLogoutModal(false);

  const handleLogout = () => {
    logout()
    navigate("/");
  };

  return (
    <>
      <header
        id="pq-header"
        className="pq-header-default pq-has-sticky sticky-top red-shadow"
      >
        <div className="pq-top-header ">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-md-6 text-right">
                <div className="pq-header-social text-right">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/chilliesuganda"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/chilliesuganda/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/chilliesuganda"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/c/256752797000"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pq-header-contact ">
                  <ul>
                    <li>
                      <a href="tel:+256-772-849-936">
                        <i className="fas fa-phone"></i>
                        <span>+256-772-849-936 / +256-752-797-007</span>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@peacefulqode.com">
                        <i className="fas fa-envelope"></i>
                        <span>info@chillies.co.ug</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pq-bottom-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <Link className="navbar-brand" to="/">
                    <img className="img-fluid logo" src={Logo} alt="chillies" />
                  </Link>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <div id="pq-menu-contain" className="pq-menu-contain">
                      <ul id="pq-main-menu" className="navbar-nav ml-auto">
                        <li className="menu-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/about">About Us</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/menu">our menu</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/carrers">Careers</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/location">Location</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/contact-us">contact us</Link>
                        </li>
                        <li className="menu-item d-none d-lg-inline">
                          <Link className="btn btn-link p-0" to="/cart">
                            <i
                              className="fas fa-shopping-cart p-1"
                              style={{ fontSize: "17px" }}
                            ></i>
                            <sup
                              className="badge bg-danger rounded-0"
                              style={{
                                verticalAlign: "super",
                                fontSize: "9px",
                              }}
                            >
                              {cartItems?.length}
                            </sup>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="d-md-none row">
                    <Link to={"/cart"} className="col-auto btn btn-link">
                      <i
                        className="fas fa-shopping-cart p-1 text-danger"
                        style={{ fontSize: "25px" }}
                      ></i>
                      <sup
                        className="badge bg-danger p-1 rounded-0"
                        style={{
                          verticalAlign: "super",
                          fontSize: "9px",
                        }}
                      >
                        {cartItems?.length}
                      </sup>
                    </Link>
                    {!isLoggedIn && (
                      <Link to="/login" className="btn btn-outline-danger col-auto">
                        <div className="pq-button-block">
                          <span className="pq-button-text">Sign In</span>
                        </div>
                      </Link>
                    )}
                    {isLoggedIn && (
                      <div className="dropdown col-auto pt-1">
                        <button
                          className="btn btn-outline-danger"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-user"></i>{" "}
                        </button>
                        <ul
                          className="dropdown-menu text-sm border-0"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link className="dropdown-item" to="/orders">
                              Order History
                            </Link>
                          </li>
                          
                          <li>
                            <Link className="dropdown-item" to="/profile">
                              Profile
                            </Link>
                          </li>
                          <li>
                            <button
                              onClick={handleLogoutModalOpen}
                              className="btn btn-link dropdown-item text-sm"
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                  {!isLoggedIn && (
                    <div className="pq-btn-container">
                      <Link to="/login" className="btn btn-outline-danger">
                        <div className="pq-button-block">
                          <span className="pq-button-text">Sign In</span>
                        </div>
                      </Link>
                    </div>
                  )}
                  {isLoggedIn && (
                    <div className="dropdown px-4 d-none d-lg-block">
                      <button
                        className="btn btn-outline-danger"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-user"></i>{" "}
                      </button>
                      <ul
                        className="dropdown-menu text-sm border-0"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        
                        <li>
                          <Link className="dropdown-item" to="/orders">
                            Order History
                          </Link>
                        </li>
                   
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            Profile
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={handleLogoutModalOpen}
                            className="btn btn-link dropdown-item text-sm"
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <ConfirmModal
        message={"Are You Sure You Want To Logout?"}
        onConfirm={handleLogout}
        onCancel={handleLogoutModalClose}
        show={logoutModal}
        setShow={setLogoutModal}
      />
    </>
  );
};

export default Header;
