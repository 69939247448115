
import { useEffect } from "react";
const Carrers = () => {
    useEffect(() => {
      document.title = "Carrers - Chillies Restuarant";
    }, []);
  return (
    <>
      <section className="booking-form">
        <div className="container">
          <div className="row">
            <div className="pq-section pq-style-1 text-center pt-4">
              <span className="pq-section-sub-title">Join Our Team</span>
              <p className="pq-section">
                Are you passionate about and ready to make a significant impact
                in a dynamic and collaborative work environment? We are actively
                seeking dedicated individuals to join our team and contribute to
                our mission.{" "}
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center">
            <form className="pq-booking-table-form col-md-6">
              <div className="row text-center">
                <div className="col-lg-6 col-md-6">
                  <input type="text" placeholder="Your Firstname" />
                </div>
                <div className="col-lg-6 col-md-6">
                  <input type="text" placeholder="Your Lastname" />
                </div>
                <div className="col-lg-6 col-md-6">
                  <input type="text" placeholder="Your Phone" />
                </div>
                <div className="col-lg-6 col-md-6">
                  <input type="text" placeholder="Your Email" />
                </div>
                <div className="col-lg-6 col-md-6">
                  <select>
                    <option defaultValue="">
                      Select Position
                    </option>
                    <option value="">Barista</option>
                    <option value="">Kitchen Staff</option>
                    <option value="">Chefs</option>
                    <option value="">Waiter / Waitress</option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-6">
                  <input type="file" className="form-control" />
                </div>
                <div className="col-lg-12">
                  <button href="#" className="btn btn-danger">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Carrers;
