import { Link } from "react-router-dom";
import { useEffect } from "react";
import GnutsImage from "assets/imgs/other/body/Cp_IMG_6750.jpg";
import CupImage from "assets/imgs/other/body/NNP_0269.jpg";
import TestimonalImage from "assets/imgs/other/body/Emirates_logo-2.png";
import EquityLogo from "assets/imgs/other/body/equity-bank-logo-85CF7A0C16-seeklogo.com_-2.png";
import DfcuLogo from "assets/imgs/other/body/dfcu-2.png";
import Image1 from "assets/imgs/other/body/1.png";
import Image2 from "assets/imgs/other/body/2.png";
import Banner from "assets/imgs/other/body/banner.png";
import MenuCategoriesComponent from "./menu-logic/menu-categories";
const Home = () => {
  useEffect(() => {
    document.title = "Home - Chillies Restuarant";
  }, []);
  return (
    <>
      <section className="about-us pq-pt-30 pq-bg-dark pb-xl-0 overflow-hidden">
        <img
          src={Image2}
          className="pq-floating-image-right wow animated fadeInRight"
          alt=""
        />
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-5 col-md-12 banner-center-sm pq-px-60">
              <div className="pq-section pq-style-1 wow animated fadeInUp">
                <span className="pq-section-sub-title">
                  make an order online
                </span>
                <h5 className="pq-section-title">Fast Food Restaurant</h5>
              </div>
              <p className="pq-about-description wow animated fadeInUp">
                Chillies is home to Kampala's best restaurants, with over 8
                unique 'quick-bite' eateries for our visitors to choose from
                from 7 locations.
              </p>
              <Link to="/menu" className="btn btn-dark p-2 rounded-0">
                Make Order
              </Link>
            </div>
            <div className="col-xl-7 col-md-12 mt-4 mt-xl-0 p-xl-0 d-none d-md-block">
              <div className="pq-img-gallery pq-me-20 wow animated fadeInRight">
                <div className="row container">
                  <div className="item col-md-12">
                    <img src={Banner} loading="lazy" alt="noimage" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#cccccc" }}>
        <div className="container">
          <div
            className="pq-section pq-style-1 text-center"
            style={{ marginTop: "-40px" }}
          >
            <span className="pq-section-sub-title">our menu</span>
          </div>
          <MenuCategoriesComponent />
        </div>
      </section>
      <section
        className="about pq-bg-img-8 pq-pt-0"
        style={{ marginTop: "-70px" }}
      >
        <img
          src={Image1}
          className="pq-floating-image-left wow animated fadeInLeft"
          alt=""
        />
        <div className="container">
          <div className="row flex-column-reverse flex-xl-row">
            <div className="col-xl-6 ps-xl-5 wow animated fadeInRight">
              <div className="pq-about-us-img text-end">
                <img
                  src={CupImage}
                  loading="lazy"
                  className="pq-about-us-img-3"
                  alt=""
                />
                <img
                  src={GnutsImage}
                  className="pq-about-us-img-4"
                  loading="lazy"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-6 mt-4 mt-xl-0 wow animated fadeInLeft">
              <div className="pq-section pq-style-1">
                <span className="pq-section-sub-title">about us</span>
                <h5 className="pq-section-title">
                  Chillies One Of The Best Food Service
                </h5>
              </div>
              <p>
                If you're looking for somewhere to eat out in Kampala, we
                feature flavours from around the world and options to suit every
                budget.
              </p>
              <div className="row pq-mb-40">
                <div className="col-lg-6 col-md-6">
                  <ul className="pq-list-check">
                    <li>
                      <i className="fas fa-check"></i>
                      <h6>Local Foods</h6>
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      <h6>Skilled Chefs</h6>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 mt-3 mt-md-0">
                  <ul className="pq-list-check">
                    <li>
                      <i className="fas fa-check"></i>
                      <h6>Vegan Options</h6>
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      <h6>Fresh Foods</h6>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pq-counter-box pq-mb-40">
                <div className="pq-counter-box-counter">
                  <div className="pq-counter-num-prefix">
                    <h6 className="timer" data-to="50" data-speed="2000">
                      500 M
                    </h6>
                    <span
                      className="pq-counter-prefix"
                      style={{ fontSize: "12px" }}
                    >
                      +
                    </span>
                  </div>
                  <span
                    className="pq-counter-title"
                    style={{ fontSize: "12px" }}
                  >
                    Happy Customers
                  </span>
                </div>
                <p className="pq-counter-box-description">
                  You want to taste Ugandan food, Chillies restaurant is the
                  best place . It prepares "Mponbo".
                </p>
              </div>
              <Link className="btn  btn-danger mb-2 float-end" to="/about">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial" style={{ background: "#cccccc" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="pq-section pq-style-1">
                <span className="pq-section-sub-title text-white">
                  our testimonials
                </span>
                <h5 className="pq-section-title">
                  what they're say about us ?
                </h5>
              </div>
            </div>
            <div className="col-xl-8 mt-4 mt-xl-0">
              <div className="pq-testimonial-box-slider pq-style-2">
                <div className="row">
                  <div className="item col-md-4">
                    <div className="pq-testimonial-box pq-style-2">
                      <div className="pq-testimonial-content">
                        <div className="pq-quote">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <p className="pq-testimonial-description">
                          The breakfast brunch was great. We went at 10:45 and
                          everything was still hot and there was plenty of
                          everything. Our server was very friendly and took good
                          care of us. We also loved the free wifi..
                        </p>
                      </div>
                      <div className="pq-testimonial-media">
                        <div className="pq-testimonial-img">
                          <img
                            decoding="async"
                            src={TestimonalImage}
                            alt="Testimonial"
                          />
                        </div>
                        <div className="pq-testimonial-meta">
                          <h5 className="pq-testmonial-title">Ritah,</h5>
                          <span> Emirates staff</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item col-md-4">
                    <div className="pq-testimonial-box pq-style-2">
                      <div className="pq-testimonial-content">
                        <div className="pq-quote">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <p className="pq-testimonial-description">
                          The food is absolutely wonderful, from preparation to
                          presentation, very pleasing. We especially enjoy the
                          special charcoal grilled chicken, the breakfast combo
                          and the fruit salad is great (even took photos so we
                          could try to replicate at home).
                        </p>
                      </div>
                      <div className="pq-testimonial-media">
                        <div className="pq-testimonial-img">
                          <img
                            decoding="async"
                            src={EquityLogo}
                            alt="Testimonial"
                          />
                        </div>
                        <div className="pq-testimonial-meta">
                          <h5 className="pq-testmonial-title">
                            Kabalange Smith ,
                          </h5>
                          <span> Equity</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item col-md-4">
                    <div className="pq-testimonial-box pq-style-2">
                      <div className="pq-testimonial-content">
                        <div className="pq-quote">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <p className="pq-testimonial-description">
                          “The food is ever fresh, properly prepared and a great
                          value for the price. We highly recommend it. The
                          breakfast combo on Friday is equally good. “
                        </p>
                      </div>
                      <div className="pq-testimonial-media">
                        <div className="pq-testimonial-img">
                          <img
                            decoding="async"
                            src={DfcuLogo}
                            alt="Testimonial"
                          />
                        </div>
                        <div className="pq-testimonial-meta">
                          <h5 className="pq-testmonial-title">General</h5>
                          <span> DFCU Bank</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="client pq-bg-img-1" style={{ marginTop: "-50px" }}>
        <div className="pq-section pq-style-1 text-center">
          <span className="pq-section-sub-title text-white text-center">
            our Partners / Clients
          </span>
          <div className="pq-bg-grey">
            <div className="container"></div>
            <div className="row">
              <div className="col-lg-12 wow animated fadeInUp">
                <div className="pq-client-box pq-client-style-1 pq-client-padding">
                  <div className="row">
                    <div className="item col-md-2">
                      <div className="pq-client-box pq-style-1">
                        <Link>
                          <img
                            decoding="async"
                            src={DfcuLogo}
                            alt="pq-client-img"
                            className="pq-client-img"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="item col-md-2">
                      <div className="pq-client-box pq-style-1">
                        <Link>
                          <img
                            decoding="async"
                            src={EquityLogo}
                            alt="pq-client-img"
                            className="pq-client-img"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="item col-md-2">
                      <div className="pq-client-box pq-style-1">
                        <Link>
                          <img
                            decoding="async"
                            src={TestimonalImage}
                            alt="pq-client-img"
                            className="pq-client-img"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
