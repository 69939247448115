import { Modal, Table } from "react-bootstrap";
const OrderHistorySummary = ({ handleCloseModal, showModal, orderItems }) => {
    // console.log(orderItems)
  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>Order Items</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>UGX {item.price.toLocaleString()}</td>
                  <td>{item.qty}</td>
                  <td>UGX {(item.qty * item.price).toLocaleString()}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="4" className="text-end">
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>
                    UGX{" "}
                    {orderItems
                      .reduce((a, c) => a + c.price * c.qty, 0)
                      .toLocaleString()}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-secondary text-small"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderHistorySummary;
