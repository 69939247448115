import axios from "axios";

// const BASE_URL = "http://localhost/chillies";
// const BASE_URL = "http://192.168.1.109/chillies";
const BASE_URL = "https://api.chillies.co.ug/v1";


export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": localStorage.getItem("accessToken"),
  },
  withCredentials: false,
});
