import Footer from "pages/components/footer";
import Header from "pages/components/header";
import { Outlet } from "react-router-dom";

const Layout = ({ onAdd, onRemove, cartItems, onDelete , isAuth}) => {
  
  return (
    <>
      <Header
        cartItems={cartItems}
        onAdd={onAdd}
        onRemove={onRemove}
        onDelete={onDelete}
        isAuth={isAuth}
      />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
