import { useEffect } from "react";
import {useParams} from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { axiosPrivate } from "config/axios";

const OrderTrack = () => {
   useEffect(() => {
     document.title = "Track Order - Chillies Restuarant";
   }, []);
   const params = useParams();

   const { id } = params;

     const headers = {
       Authorization: localStorage.getItem("accessToken"),
       "Content-Type": "application/json",
     };

     const { data } = useQuery({
       queryKey: ["order-track"],
       queryFn: async () => {
         try {
           const response = await axiosPrivate.get(
             `/client/track-order/${id}`,
             {
               headers,
             }
           );
           return response.data.data.status;
         } catch (error) {
           console.error("Axios request failed", error);
           throw error;
         }
       },
       placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
     });
    //  console.log(data)
  return (
    <>
      <section className="container mt-5">
        <div className="text-center mb-3">
          <h6 className="mb-2">Track Order</h6>
        </div>

        <div className="row">
          <div className="card mb-3 shadow-lg">
            <div className="p-4 text-center text-white text-lg bg-danger rounded-top">
              <span className="text-uppercase">Tracking Order No - </span>
              <span className="text-medium">{id}</span>
            </div>
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
              {/* <div className="w-100 text-center py-1 px-2">
                <span className="text-medium">Date:</span> SEP 09, 2017
              </div> */}
            </div>
            <div className="card-body">
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <div
                  className={`step ${
                    data === "pending" ||
                    data === "processing" ||
                    data === "delivery" ||
                    data === "delivered" ||
                    data === "completed"
                      ? "completed"
                      : ""
                  }`}
                >
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      <i className="fa fa-shopping-cart"></i>
                    </div>
                  </div>
                  <h4 className="step-title">Confirmed Order</h4>
                </div>
                <div
                  className={`step ${
                    data === "processing" ||
                    data === "delivery" ||
                    data === "delivered" ||
                    data === "completed"
                      ? "completed"
                      : ""
                  }`}
                >
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      <i className="fa fa-spinner"></i>
                    </div>
                  </div>
                  <h4 className="step-title">Processing Order</h4>
                </div>
                <div
                  className={`step ${
                    data === "on delivery" ||
                    data === "delivered" ||
                    data === "completed"
                      ? "completed"
                      : ""
                  }`}
                >
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      <i className="fa fa-truck"></i>
                    </div>
                  </div>
                  <h4 className="step-title">On Delivery</h4>
                </div>
                <div
                  className={`step ${
                    data === "delivered" || data === "completed"
                      ? "completed"
                      : ""
                  }`}
                >
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      <i className="fa fa-rocket"></i>
                    </div>
                  </div>
                  <h4 className="step-title">Order Delivered</h4>
                </div>
                <div
                  className={`step ${data === "completed" ? "completed" : ""}`}
                >
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      <i className="fa fa-check"></i>
                    </div>
                  </div>
                  <h4 className="step-title">Confirmed Delivery</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
            <div className="custom-control custom-checkbox mr-3">
              <input
                className="custom-control-input"
                type="checkbox"
                id="notify_me"
                defaultChecked={true}
              />
              <label className="custom-control-label" htmlFor="notify_me">
                Notify me when order is delivered
              </label>
            </div>
            {/* <div className="text-left text-sm-right">
              <button
                className="btn btn-danger btn-rounded"
              >
                View Order Details
              </button>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderTrack;
